
import React, { useEffect, useState } from 'react'
import cascading from '../assests/css/footerstyle.module.css';
import { FaTelegramPlane } from "react-icons/fa";
import logo from '../assests/images/footerlogo.png';
import minilogo from '../assests/images/logomini.png';

import media1 from '../assests/images/media1.png';
import mediahover1 from '../assests/images/mediahover1.png';

import media2 from '../assests/images/media2.png';
import mediahover2 from '../assests/images/mediahover2.png';

import media3 from '../assests/images/media3.png';
import mediahover3 from '../assests/images/mediahover3.png';

import media4 from '../assests/images/media4.png';
import mediahover4 from '../assests/images/mediahover4.png';

import { Link } from 'react-router-dom';
import { IoMdMail } from "react-icons/io";
import { FaFile } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";


import { CHAINS, FRONT_URL, ANALYTICS_URL } from '../config/env';
import { getTokenPricesFromFarm } from '../hooks/usePools';
import { getNativeAddress } from '../hooks/useNativeToken';
import { roundToSignificant } from '../hooks/useCommon';

import { useSelector } from 'react-redux';

import footertop from "../assests/images/galfi/footertop.png";
import headerlogo from "../assests/images/headerlogo.png";
import { FaMedium, FaDiscord} from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";


export default function Footer() {
    const { totalTvl } = useSelector((state) => (state.nftpool))
    const [nativePrice, SetnativePrice] = useState(0)
    useEffect(() => {
        NativePrice()
    }, [])
    const NativePrice = async () => {
        try {
            let farms = JSON.parse(localStorage.getItem("Farms"))
            let price = await getTokenPricesFromFarm(farms)
            let nativeAddress = getNativeAddress()
            let dspinusd = price[nativeAddress.toLowerCase()]
            SetnativePrice(dspinusd)
        } catch (err) {
            console.log(err, 'NativePrice__Err')
        }
    }
    return (
        <>
            <div className={`${cascading.footersec}`}>
                <div className={`container`}>
                    <img src={footertop} className={`img-fluid w-100`} />
                    <div className={`row align-items-center ${cascading.row_marginminus}`}>
                        <div className={`col-sm-12 col-md-3 col-lg-3 col-xl-4 col-xxl-3 mt-3`}>
                            <img src={headerlogo} className={`img-fluid ${cascading.footlogoset1}`} />
                        </div>
                        <div className={`col-sm-12 col-md-5 col-lg-5 col-xl-4 col-xxl-4 mt-3`}>
                            <p className={`${cascading.copytext} mb-0`}>Copyright © 2024 GALFI - All Rights Reserved.</p>
                        </div>
                        <div className={`col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-5 mt-3`}>
                            <div className={`${cascading.solink}`}>
                            <Link to="https://medium.com/@marketing_1952" target='_blank'>
                                  <FaMedium/>
                            </Link>
                            <Link to="https://discord.com/invite/7HDCQssqv9" target='_blank'>
                                  <FaDiscord/>
                            </Link>
                            <Link to="https://t.me/+zilpXHI3PbZhZTRk" target='_blank'>
                                  <FaTelegramPlane />
                            </Link>
                            <Link to="https://x.com/GalFiofficial" target='_blank'>
                                  <FaXTwitter/>
                            </Link>
                            </div>
                        </div>
                    </div>


                </div>

            </div>



        </>
    )
}

