import React, { useEffect, useState } from 'react';
import cascading from '../assests/css/comingsoon.module.css';
import Footer from '../common/footer'
import Header from '../common/header';
import { Link } from 'react-router-dom';


import logo_bigger from '../assests/images/logo_bigger.png';




export default function Comingsoon() {
   
    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.comingpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <img src={logo_bigger} className={`img-fluid ${cascading.logocoming}`} />
                        <p className={`${cascading.pagehead}`}>Coming Soon</p>
                        <p className={`${cascading.pagedesc} mt-3`}>In the meantime, enter the app and begin your trading journey now.</p>
                        <Link to="/" className={`btn mt-3 ms-4 ${cascading.connect_btn_theme}`}>Trade Now</Link>
                   
                   
                    </div>

                </div>


            </div>



            <Footer />
        </>
    )
}
