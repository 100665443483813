import React, { useEffect, useState } from 'react'
import { Modal, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import cascading from '../assests/css/tokenstyle.module.css';
import bnblogo from '../assests/images/bnb.png';
import InfoIcon from '../assests/images/infoicon.png'
import { useDispatch, useSelector } from 'react-redux';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { IoMdClose } from "react-icons/io";

import { FaRegQuestionCircle } from "react-icons/fa";

import Walletmodal from './walletmodal';

import Importtokenmodal from './importtokenmodal';

//lib
import { isEmpty } from "../lib/isEmpty";
import { getWalletAddress, getuserTokens, walletControl } from '../lib/localStorage';
import fileObjectUrl from '../lib/img'

//hooks
import { getTokenBalance, GetCurrencyBalance } from '../hooks/useTokens'
import { getTokens } from '../hooks/useTokens'
import { IsCurrency, Significant } from '../hooks/useCommon'
import { TokenInfo } from '../hooks/useErc20';
import { CURRENT_CHAIN_ID, getChainId } from '../hooks/useWeb3';


const tokendetail = {
  results: [{
    coinimg: bnblogo,
    tokename: "BNB",
    tokendesc: "BNB",
    balance: "0.666"

  },
  {
    coinimg: bnblogo,
    tokename: "BUSD",
    tokendesc: "BUSD Token",
    balance: "0.0"
  },
  {
    coinimg: bnblogo,
    tokename: "BNB",
    tokendesc: "BNB",
    balance: "0.0"
  },
  {
    coinimg: bnblogo,
    tokename: "BUSD",
    tokendesc: "BUSD Token",
    balance: "0"
  }]


};
export default function Selecttoken(props) {
  console.log(props, "props")
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setTokens] = useState([])
  const [searchResults, setSearchResults] = useState([]);
  const [activebtn, setActivebtn] = useState("")
  const [balance, setBalance] = useState(0)
  const [walletmodalshow, setWalletmodalshow] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [importToken, SetimportToken] = useState({})
  const [isToken, SetisToken] = useState(false)
  const [showText, SetshowText] = useState('')
  const [showimport, setShowimport] = useState(false);
  const [selecttokenmodal, setSelecttokenmodal] = useState(true)
  const {usertokens} =  useSelector((state)=>(state.token))
  const { walletaddress } = useSelector((state) => (state.wallet))

  const handleChange = e => {
    let addressRegx = "^(0x)[0-9A-Fa-f]{40}$"
    var pattern = new RegExp(addressRegx)
    setSearchTerm(e.target.value);
    if (!isEmpty(e.target.value)) {
      let regx = new RegExp(e.target.value, 'i')
      let results = token.filter((val) => {
        return (val?.symbol?.match(regx) || val?.name?.match(regx) || val?.address?.match(regx))
      })
      if (isEmpty(results)) {
        if ((pattern.test(e.target.value))) {
          CheckToken(e.target.value)
        } else {
          SetisToken(false)
          SetshowText('The token was not found ,please paste the address and import the token')
        }
      }
      setSearchResults(results);
    } else {
      setSearchResults(token);
    }
    console.log(searchResults, "search")
  };

  const CheckToken = async (token) => {
    try {
      let checkToken = await TokenInfo(token)
      console.log(checkToken, 'checkToken')
      if (!checkToken) {
        SetisToken(false)
        SetshowText('Pasted address is not a token')
      } else if (!isEmpty(checkToken)) {
        console.log(checkToken, 'checkToken')
        let importToken = { ...checkToken }
        importToken['chainId'] = CURRENT_CHAIN_ID()
        importToken['image'] = InfoIcon
        SetisToken(true)
        SetimportToken(importToken)
      } else {
        SetisToken(false)
      }
    } catch (err) {
      console.log(err, 'CheckToken__err')
    }
  }


  useEffect(() => {
    if (!isEmpty(getWalletAddress())) {
      TokensBalance()
    }
  }, [walletaddress,usertokens])

  const TokensBalance = async () => {
    try {
      setLoading(true)
      console.log('TokensBalance')
      let Token = getTokens()
      let tokens = JSON.stringify(Token)
      tokens = JSON.parse(tokens)
      // let userTokens =  getuserTokens()
      // tokens = [...tokens,...userTokens]
      let calls = []
      let balance = []
      console.log(tokens, 'TokensBalance')
      for (let i = 0; i < tokens.length; i++) {
        let val = tokens[i]
        if (!IsCurrency(val)) {
          console.log(val, 'TokensBalance')
          let obj = {
            address: val?.address,
            name: 'balanceOf',
            params: [getWalletAddress()]
          }
          calls.push(obj)
        } else {
          balance[i] = await GetCurrencyBalance()
        }
      }
      let balan = await getTokenBalance(calls)
      balance = [...balance,...balan]
      console.log("TokensBalance_balance", balance)
      for (let j = 0; j < tokens.length; j++) {
        tokens[j]['balance'] = balance[j]
        if (j == tokens.length - 1) {
          console.log(tokens, 'Token')
          setSearchResults(tokens)
          setTokens(tokens)
          setLoading(false)
        }
      }
      setBalance(balance)
    } catch (err) {
      console.log(err, "TokensBalance__Err")
    }
  }
  return (

    <div className={`${cascading.walletmodalstyle}`}>
      {console.log(props, "checking")}
      <Modal centered show={selecttokenmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Select a Token</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          {walletaddress && !isEmpty(walletaddress) ?
            Loading && Loading ?
              <div className={`${cascading.commonsec}`}>
                <p className={`${cascading.labels}`}>Loading...</p>
              </div> :
              <>
                <div className={`${cascading.topsearchsec}`}>
                  <input
                    type="text"
                    placeholder="Search name or paste address"
                    value={searchTerm}
                    onChange={handleChange}
                    className={`${cascading.searchinput}`}
                  />
                </div>
                {
                  isToken ?
                    <>
                      <div className={`${cascading.plandiv}`}>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div>
                            <FaRegQuestionCircle className={`${cascading.whiteinfo} me-2`} />
                            <span className={`${cascading.plantext} me-2`}>{importToken.symbol}</span>
                            <span className={`${cascading.planname}`}>{importToken.name}</span>
                          </div>
                          <div>
                            <Button className={`${cascading.btnstyle}`} onClick={() => { setShowimport(true); setSelecttokenmodal(false); }}>
                              Import
                            </Button>
                          </div>
                        </div>

                      </div>
                    </> :
                    <>
                      {props.commontoken &&
                        <div className={`${cascading.commonsec}`}>
                          <p className={`${cascading.labels}`}>Common bases<OverlayTrigger
                            className={`${cascading.tooltip_style}`}
                            key="right"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-right`}>
                                These tokens are commonly paired with other tokens.
                              </Tooltip>
                            }
                          >
                            <span className={`mx-2`} ><IoIosHelpCircleOutline fill='#fff' fontSize={18} /></span>
                          </OverlayTrigger></p>
                          <div className={`${cascading.btngrpsec}`}>
                            <ButtonGroup aria-label="Basic example">
                              {searchResults.map((e, i) => (
                                e.commonBase == true ?
                                  <Button variant="secondary" id={i} onClick={() => {
                                    console.log(e, 'SetToken')
                                    let obj = { ...e }
                                    setActivebtn(e)
                                    // obj['balance'] = balance[i]
                                    props.SetToken(obj)
                                    props.onHide()
                                  }} ><img src={fileObjectUrl(e?.image)} alt='img' className={`img-fluid ${cascading.tokenimgs}`} /> {e?.symbol}</Button>
                                  : ""
                              ))}
                            </ButtonGroup>
                          </div>


                          {/* <Button variant="secondary" className={activebtn == i ? `${cascading.activebtn}` : ""} id={i} onClick={() => setActivebtn(i)}><img src={fileObjectUrl(e?.image)} alt='img' className={`img-fluid ${cascading.tokenimgs}`} /> {e?.symbol}</Button> */}


                        </div>
                      }
                      {searchResults.length > 0 ? <ul className={`${cascading.tokenlists}`}>
                        {searchResults.map((e, i) => (
                          <li key={i} onClick={() => {
                            console.log(e, 'SetToken')
                            let obj = { ...e }
                            // obj['balance'] = balance[i]
                            props.SetToken(obj)
                            props.onHide()
                          }}>
                            <div className={`${cascading.tokenrow}`}>
                              <img src={isEmpty(e?.image) ?InfoIcon:  fileObjectUrl(e?.image)} alt='coin' className={`img-fluid ${cascading.timg}`} />
                              <div>
                                <p className={`${cascading.name}`}>{e?.symbol}</p>
                                <p className={`${cascading.desc}`}>{e?.name}</p>
                              </div>
                            </div>
                            <div>
                              {console.log("e.balance", e.balance)}
                              {/* <p className={`${cascading.desc}`}>{isEmpty(balance[i]) ? 0 : parseFloat(balance[i])?.toFixed(2)}</p> */}
                              <p className={`${cascading.desc}`}>{isEmpty(e.balance) ? 0 : parseFloat(e?.balance).toFixed(8)}</p>
                            </div>
                          </li>
                        ))}
                      </ul> :
                        <p className='text-center text-white px-3'>{showText}</p>
                      }
                    </>
                }
              </>
            :
            <div className='px-4 py-5'>
              <button
                className={`btn ${cascading.connect_btn}`}
                onClick={() => setWalletmodalshow(true)}>
                Connect Wallet
              </button>
            </div>
          }
        </Modal.Body>

      </Modal>

      {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
      {showimport && <Importtokenmodal
        Token={importToken}
        show={showimport}
        onHide={() => {
          setShowimport(false);
          setSelecttokenmodal(true);
          SetisToken(false)
          setSearchTerm('')
        }}
      />
      }

    </div>
  )
}